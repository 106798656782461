import React, { useRef, useState } from "react";
import $ from "./mainSection.module.scss";
import useIsMobile from "../../hooks/useIsMobile";

export const MainSection = ({ scrollToSection }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useIsMobile();

  const menu_btn = useRef(null);
  const curtain_menu = useRef(null);

  const changeClass = () => {
    if (isMobile) {
      if (!isMenuOpen && menu_btn.current) {
        curtain_menu.current.style.width = "100%";
        menu_btn.current.classList.toggle($.change);
        setIsMenuOpen(true);
      } else {
        menu_btn.current.classList.toggle($.change);
        curtain_menu.current.style.width = "0";
        setIsMenuOpen(false);
      }
    }
  };

  const onclickScrollToSection = ({ section }) => {
    changeClass();
    scrollToSection({ section });
  };

  return (
    <>
      <header className={$.main_section_header}>
        <div className={$.main_section_header_logo}>
          Filin Development <span>.</span>
        </div>
        <div className={$.main_section_header_menu} ref={curtain_menu}>
          <ul>
            <li
              onClick={() =>
                onclickScrollToSection({ section: "services_section" })
              }
            >
              Services
            </li>
            <li
              onClick={() =>
                onclickScrollToSection({ section: "process_section" })
              }
            >
              Process
            </li>
            <li
              onClick={() =>
                onclickScrollToSection({ section: "about_section" })
              }
            >
              About
            </li>
            <li
              onClick={() =>
                onclickScrollToSection({ section: "contact_section" })
              }
              className={$.contact_btn}
            >
              Contact<span></span>
            </li>
          </ul>
        </div>
        <div
          className={$.burger_menu_closed}
          ref={menu_btn}
          onClick={() => changeClass()}
        >
          <div className={$.bar1}></div>
          <div className={$.bar2}></div>
          <div className={$.bar3}></div>
        </div>
      </header>
      <section className={$.main_section} id="main_section">
        <div className={$.main_section_text_wrapper}>
          <div className={$.main_section_text_wrapper_container}>
            <h1>
              We do web development.
              <br />
              We help businesses
              <br /> to gain their outcome.
            </h1>
            <h4>
              <mark style={{ backgroundColor: "#66ac82 !important" }}>
                Driven by Passion, Defined by Growth, Dedicated to Your Future.
              </mark>
            </h4>
          </div>
          <div>
            <img
              className={$.main_section_image}
              src=""
              alt="Canada web development company"
            ></img>
          </div>
        </div>
      </section>
    </>
  );
};
