import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import $ from "./contact.module.scss";

export const Contact = () => {
  const [state, handleSubmit] = useForm("mnqejqbk");
  const [isChecked, setIsCheked] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
    "budget-from": "", // Note: Adjusted to match the name attribute in the input element
    "budget-to": "", // Note: Adjusted to match the name attribute in the input element
    "date-to-sart": `${new Date().toISOString().slice(0, 10)}`,
  });

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
    if (name === "consent") setIsCheked(!isChecked);
    if (
      (name === "phone" || name === "budget-to" || name === "budget-from") &&
      value !== "" &&
      !phoneRegex.test(value)
    ) {
    } else {
      let dataUpdated = {
        ...formData,
        [name]: value,
      };
      setFormData(dataUpdated);
    }
  };

  return (
    <section id="contact_section">
      <div className={$.form}>
        <div className={$.form_container}>
          <div className={$.form_container_header}>
            <h1>
              {!state.succeeded
                ? "Feel free to contact us"
                : "Thank you! Your message sent!"}
            </h1>
          </div>
          {!state.succeeded && (
            <form
              className={$.form_container_actual_form}
              onSubmit={handleSubmit}
            >
              <div>
                <div className={$.form_container_actual_form_input}>
                  <input
                    className={$.input}
                    value={formData["name"]}
                    type="text"
                    name="name"
                    id="name-input"
                    placeholder="Name *"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className={$.form_container_actual_form_input}>
                  <input
                    className={$.input}
                    type="text"
                    value={formData["company"]}
                    name="company"
                    id="company-input"
                    placeholder="Company/Industry *"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <div className={$.form_container_actual_form_input}>
                  <input
                    className={$.input}
                    value={formData["email"]}
                    type="email"
                    name="email"
                    id="email-input"
                    placeholder="Email *"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className={$.form_container_actual_form_input}>
                  <input
                    className={$.input}
                    value={formData["phone"]}
                    type="text"
                    name="phone"
                    id="phone-input"
                    placeholder="Phone"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className={$.form_container_actual_form_budget_input}>
                <h4>Your budget:</h4>
                <div
                  className={$.form_container_actual_form_budget_input_wrapper}
                >
                  <div
                    className={
                      $.form_container_actual_form_budget_input_wrapper_from
                    }
                  >
                    <input
                      className={$.input}
                      value={formData["budget-from"]}
                      onChange={handleChange}
                      type="text"
                      name="budget-from"
                      id="budget-from"
                      placeholder="$ From"
                    />
                  </div>
                  <div
                    className={$.form_container_actual_form_input_wrapper_to}
                  >
                    <input
                      className={$.input}
                      type="text"
                      name="budget-to"
                      value={formData["budget-to"]}
                      id="budget-to"
                      placeholder="$ To"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className={$.form_container_actual_form_input_start_date}>
                <h4>When would you like to start:</h4>
                <div
                  className={
                    $.form_container_actual_form_input_start_date_input_wrapper
                  }
                >
                  <input
                    value={formData["date-to-sart"]}
                    type="date"
                    className={$.input}
                    name="date-to-sart"
                    id="start-date-input"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <h4>Your Message:</h4>
                <textarea
                  onChange={handleChange}
                  type="textarea"
                  className={$.input}
                  name="message"
                  placeholder="Type Your Message here *"
                  value={formData["message"]}
                  required
                />
              </div>
              <>
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </>
              <div>
                <div className={$.form_container_actual_form_input_checkbox}>
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={isChecked}
                    required
                    name="consent"
                    onChange={() => setIsCheked(!isChecked)}
                  />
                  <span>
                    I agree that my personal information will be processed and
                    stored by Filin Development
                  </span>
                </div>
                <button type="submit" className={$.form_submit_button}>
                  send message<span></span>
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};
