import React from "react";
import $ from "./footer.module.scss";

export const Footer = () => {
  return (
    <footer className={$.footer}>
      <div className={$.footer_inner_wrapper}>
        <div></div>
        <div className={$.footer_inner_wrapper_text_box}>
          <span>© 2024 Filin development. All rights reserved</span>
        </div>
      </div>
    </footer>
  );
};
