import React from "react";
import $ from "./services.module.scss";

export const Services = () => {
  const cards = [
    {
      class: `${$.service_card} + ${$.card_1}`,
      title: "Custom \nweb development",
      desc: "Our web development process provides you with unique, effective, and scalable web solutions, ensuring a consistent and user-friendly experience.",
    },
    {
      class: `${$.service_card} + ${$.card_2}`,
      title: "Web Design",
      desc: "We specialize in creating minimalist and functional designs, tailor-made to meet your specific business needs. Our designs adapt seamlessly across various devices, ensuring a consistent and engaging user experience.",
    },
    {
      class: `${$.service_card} + ${$.card_3}`,
      title: "SEO Optimisation",
      desc: "Proper web development is the foundation of effective search engine optimization. Our team of experts specializes in ensuring full optimization for your website. We build each project from the ground up, focusing on quick loading times and optimal performance.",
    },
    {
      class: `${$.service_card} + ${$.card_4}`,
      title: "Web hosting",
      desc: "Our experience in full-stack web development, coupled with our expertise in cloud computing platforms, guarantees a reliable and scalable web infrastructure tailored to your business needs.",
    },
  ];
  return (
    <section id="services_section">
      <div className={$.service_container}>
        <div className={$.service_container_header}>
          <h1>Our Services</h1>
        </div>
        <div className={$.service_container_card_wrapper}>
          {cards.map((item, index) => (
            <div className={item.class} key={index}>
              <div className={$.card_header}>
                <h2>{item.title}</h2>
              </div>
              <div>
                <p>{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
