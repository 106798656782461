import {
  MainSection,
  Services,
  Process,
  About,
  Contact,
  Footer,
} from "./components";

function App() {
  const scrollToSection = ({ section }) => {
    const scrolElement = document.querySelector(`#${section}`).offsetTop;
    //   scrolElement.scrollIntoView({ behavior: "smooth", block: "start"});
    //   const position = ref.current.offsetTop
    window.scrollTo({
      top: scrolElement - 100,
      behavior: "smooth", // Optional: smooth scrolling
    });
  };

  return (
    <div className="App">
      <MainSection scrollToSection={scrollToSection} />
      <Services />
      <Process scrollToSection={scrollToSection} />
      <About />
      <Contact />
      <Footer id="footer_section" />
    </div>
  );
}

export default App;
